* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    height: 100vh;

    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f8f8f8;
    border: 1px solid #eee;
    /* border-radius: 8px; */
}

::-webkit-scrollbar-thumb {
    background: #ccc;
    /*border-radius: 8px;*/
}

::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.font-link {
    font-family: 'Inter', sans-serif;
}

.font-link {
    font-family: 'Sintony', sans-serif;
}
